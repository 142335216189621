export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAbMjFSbjYcP1qyzoaDTTeCHZre06fVu84',
    authDomain: 'svenson-50e5d.firebaseapp.com',
    databaseURL: 'https://svenson-50e5d.firebaseio.com',
    projectId: 'svenson-50e5d',
    storageBucket: 'svenson-50e5d.appspot.com',
    messagingSenderId: '116462727056',
    appId: '1:116462727056:web:67413eeecc963ba3'
  },
  urlNotifications: 'https://us-central1-svenson-50e5d.cloudfunctions.net/svensonBackend/notifications/createNotifications',
  urlCreateSpecialist: 'https://us-central1-svenson-50e5d.cloudfunctions.net/svensonBackend/specialist/createSpecialist',
  urlServiceSvenson: 'https://svenson.com.mx/CDC/API/'
};
