import { FilePreviewOverlayService } from './../../services/file-preview-overlay.service';
import { Component, OnInit, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'sv-photo-viewer',
  templateUrl: './photo-viewer.component.html',
  styleUrls: ['./photo-viewer.component.scss']
})
export class PhotoViewerComponent implements OnInit, OnChanges {

  @Input() photo: string;
  @Input() date: string;
  @Input() selectedDefault = false;
  @Output() selected = new EventEmitter();
  overlay = false;

  constructor(
    private previewDialog: FilePreviewOverlayService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDefault) {
      this.selected.emit({ url: this.photo, selected: changes.selectedDefault.currentValue, date: this.date });
    }
  }

  public hideOverlay() {
    this.overlay = false;
  }

  public showOverlay() {
    this.overlay = true;
  }

  public onPreview() {
    this.previewDialog.open({
      data: {
        download: true,
        image: this.photo,
        title: this.date
      }
    });
  }

  onSelect(photo: any) {
    this.selected.emit(
      {
        url: this.photo,
        selected: this.selectedDefault,
        date: this.date
      }
    );
  }

}
