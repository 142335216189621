import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { SubSink } from 'subsink';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private subs = new SubSink();

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(observer => {
      this.subs.add(
        this.authService.observerCurrentUser.subscribe(async (auth) => {
          try {
            if (auth) {
              let user = await this.authService.getUserAdmin(auth.email);
              let specialists = await this.authService.getUserSpecialists(auth.email);
              user = user.concat(specialists);
              if (user.length > 0) {
                observer.next(true);
              } else {
                this.authService.logout();
              }
            } else {
              this.router.navigate(['/auth/login']);
              observer.next(false);
            }
          } catch (error) {
            throw error;
          }
        })
      )
    })


  }
}
