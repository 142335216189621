import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TYPE_USER } from "src/app/shared/data/data";
import { Router } from '@angular/router';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  public authOptionsShown = false;
  private subs = new SubSink();
  public email = '';
  public buttons = [
    {
      id: 'favourites',
      title: 'LAYOUT_LANGUAGE.navbar.favourites',
      route: '/favoritos'
    },
    {
      id: 'users',
      title: 'LAYOUT_LANGUAGE.navbar.notifications',
      route: '/users'
    },
    {
      id: 'conversations',
      title: 'LAYOUT_LANGUAGE.navbar.conversations',
      route: '/conversations'
    },
    {
      id: 'ads',
      title: 'LAYOUT_LANGUAGE.navbar.ads',
      route: '/advertising'
    },
    {
      id: 'notifications',
      title: 'LAYOUT_LANGUAGE.navbar.promos',
      route: '/notifications'
    },
  ];
  public buttonFilter = [];
  public typeUser = TYPE_USER;
  public user: any;
  public isVertical = false;
  @HostListener('window:resize', ['$event'])
  private resize() {
    this.isVertical = window.innerWidth <= 886;
  }

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.isVertical = window.innerWidth <= 886;
    this.subs.add(
      this.authService.observerCurrentUser.subscribe(async (auth) => {
        if (auth) {
          try {
            let user = await this.authService.getUserAdmin(auth.email);
            let specialists = await this.authService.getUserSpecialists(auth.email);
            user = user.concat(specialists);
            this.email = user.length > 0 ? user[0].email : ' '
            this.user = user[0];
            if (user[0].typeUser === TYPE_USER.admin) {
              this.buttonFilter = this.buttons
            } else {
              this.buttonFilter = this.buttons.filter((button: any) => {
                return button.id !== 'ads' && button.id !== 'notifications'
              })
            }
          } catch (error) {
            throw error;
          }
        }
      })
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  public showAuthOptions() {
    this.authOptionsShown = !this.authOptionsShown;
  }

  public showAuthOptionsClose() {
    this.authOptionsShown = false;
  }

  public logout() {
    localStorage.clear();
    this.authService.logout().then(() => {
      this.authOptionsShown = !this.authOptionsShown;
      this.router.navigate(['auth/login']);
    });
  }
}