import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TYPE_CONTENT, } from './../data/data';
import { FilePreviewOverlayService } from './../../services/file-preview-overlay.service';
import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import moment from 'moment';
moment.locale('es');

@Component({
  selector: 'sv-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() id: string;
  @Input() type: number;
  @Input() image: SafeResourceUrl;
  @Input() file: any;
  @Input() title: string;
  @Input() createdAt;
  @Output() delete = new EventEmitter();
  @Output() edit = new EventEmitter();
  public overlay = false;

  constructor(
    private previewDialog: FilePreviewOverlayService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.image = this.sanitizer.bypassSecurityTrustResourceUrl(this.file);
    this.createdAt = moment(this.createdAt.seconds * 1000).format('DD MMMM, YYYY');
  }

  public onPreview() {
    this.previewDialog.open({
      data: {
        type: this.type === TYPE_CONTENT.VIDEO ? TYPE_CONTENT.VIDEO : TYPE_CONTENT.IMAGE,
        image: this.file,
        title: this.title
      }
    });
  }

  public onDelete() {
    this.delete.emit(this.id);
  }

  public onEdit() {
    this.edit.emit(this.id);
  }

  public hideOverlay() {
    this.overlay = false;
  }

  public showOverlay() {
    this.overlay = true;
  }

}
