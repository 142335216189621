import { MatSnackBarConfig } from '@angular/material/snack-bar';
import es from './../../../assets/i18n/es.json';

// export const API_URL = 'http://localhost:5001/svenson-50e5d/us-central1/svensonBackend';
export const API_URL = ' https://us-central1-svenson-50e5d.cloudfunctions.net/svensonBackend';
export const DEFAULT_LANGUAGE = 'es';
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = '[a-zA-Z0-9]*';
export const NAME_REGEX = '[a-zA-ZáéíóúÁÉÍÚÓñÑüÜ ][a-zA-ZáéíóúñÁÉÍÚÓÑüÜ ]{5,75}$';
export const PASS_MAX_LENGTH = 16;
export const PASS_MIN_LENGTH = 8;
export const ZC_REGEX = '^[0-9]{5}';
export const ZC_LENGTH = 5;
export const TYPE_CONTENT = {
    IMAGE: 0,
    VIDEO: 1
};
export const TYPE_IMAGE = {
    URL: 0,
    FILE: 1
};
export const TYPE_SUBMIT = {
    CREATE: 0,
    EDIT: 1
};

export const TYPE_USER = {
    admin: 'admin',
    specialist: 'specialist'
}

export const TYPES = [
    {
        id: 0,
        name: 'ADS_LANGUAGE.TYPE.banner'
    },
    {
        id: 1,
        name: 'ADS_LANGUAGE.TYPE.video'
    }
];
export const USERSFORADMIN = [
    {
        id: 0,
        name: 'USERS.clients'
    },
    {
        id: 2,
        name: 'USERS.prospects'
    },
    {
        id: 1,
        name: 'USERS.specialists'
    }
];

export const USERSFORSPECIALIST = [
    {
        id: 0,
        name: 'USERS.clients'
    },
    {
        id: 2,
        name: 'USERS.prospects'
    }
];

export const OFFICES = [
    {
        id: 0,
        name: 'Todas las sucursales'
    },
    {
        id: 3,
        name: 'Lomas'
    },
    {
        id: 4,
        name: 'San Angel'
    },
    {
        id: 5,
        name: 'Satélite'
    },
    {
        id: 6,
        name: 'Valle'
    },
    {
        id: 7,
        name: 'Guadalajara, Jalisco'
    },
    {
        id: 8,
        name: 'Monterrey, Nuevo Leon'
    },
    {
        id: 10,
        name: 'Santa Fe'
    },
    {
        id: 12,
        name: 'Plaza Carso'
    }
];

export const CLIENT = 'Clientes';
export const SPECIALISTS = 'Especialistas';
export const MAX_SIZE = 205000;
export const MAX_WIDTH = 1344;
export const MAX_HEIGHT = 760;
export const SNACKBAR_CONFIG: MatSnackBarConfig = {
    duration: 5000,
    horizontalPosition: 'center',
    verticalPosition: 'top'
}
export const ACTION_MODE = {
    RESET: 'resetPassword',
    VERIFY: 'verifyEmail'
};

export const TIME_PROGRAMMING = [
    { label: 'onlyToday', name: es.NOTIFICATIONS.CREATE_NOTIFICATION.onlyToday },
    { label: 'daily', name: es.NOTIFICATIONS.CREATE_NOTIFICATION.daily },
    { label: 'specificDate', name: es.NOTIFICATIONS.CREATE_NOTIFICATION.specificDate }
];

export const BRANCHOFFICES = [
    { labe: 'Lomas', name: 'Lomas', id: 3 },
    { labe: 'San Angel', name: 'San Angel', id: 4 },
    { labe: 'Valle', name: 'Valle', id: 6 },
    { labe: 'Satélite', name: 'Satélite', id: 5 },
    { labe: 'Monterrey', name: 'Monterrey', id: 8 },
    { labe: 'Guadalajara', name: 'Guadalajara', id: 7 },
    { labe: 'Santa Fe', name: 'Santa Fe', id: 10 },
    { labe: 'Plaza Carso', name: 'Plaza Carso', id: 12 },
    { labe: 'Todas las sucursales', name: 'Todas las sucursales', id: 0 }
];

export const ALL_BRANCH_OFFICES = 'Todas las sucursales';

export const ALL_ID_BRANCH = 0

export const USER_TYPES = [
    { label: 'Clientes', name: 'Clientes', id: 1 },
    { label: 'Especialistas', name: 'Especialistas', id: 2 }
];

export const ALL_RATING = 'Todas las calificaciones';

export const RATING = [
    { label: 'Todas las calificaciones', name: 'Todas las calificaciones' },
    { label: '5', name: '5' },
    { label: '4', name: '4' },
    { label: '3', name: '3' },
    { label: '2', name: '2' },
    { label: '1', name: '1' }
];

export const GENDER_USER = [
    { label: 'Hombres', name: 'Hombres', id: 1 },
    { label: 'Mujeres', name: 'Mujeres', id: 2 },
];

export const DAILY_SCHEDULE = [
    { label: '10:00', value: '10:00' },
    { label: '17:00', value: '17:00' }
]

export const SPECIFIC_DATE_SCHEDULE = [
    { label: '09:00', value: '09:00' },
    { label: '13:00', value: '13:00' },
    { label: '18:00', value: '18:00' },
]

export const TIME_PROGRAMMING_TYPES = {
    DAILY: 'Diariamente',
    SPECIFIC_DATE: 'Fecha en específico',
    ONLY_TODAY: 'Solamente hoy'
}
