import { DEFAULT_LANGUAGE } from './shared/data/data';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SVENSON-Admin';
  public isHomeRoute = true;

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private router: Router,
    private location: Location,
  ) {
    router.events.subscribe(() => {
      this.isHomeRoute = (!location.path().includes('/auth/') && !location.path().includes('/action'));
    });
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(DEFAULT_LANGUAGE);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(DEFAULT_LANGUAGE);

    iconRegistry
      .addSvgIcon('ic-arrow-l', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_left_arrow.svg'))
      .addSvgIcon('ic-back', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_back.svg'))
      .addSvgIcon('ic-back-w', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_back_white.svg'))
      .addSvgIcon('ic-calendar', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_calendar.svg'))
      .addSvgIcon('ic-check', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_check.svg'))
      .addSvgIcon('ic-client-n', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_client_number.svg'))
      .addSvgIcon('ic-clients', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_clients.svg'))
      .addSvgIcon('ic-clients-w', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_clients_white.svg'))
      .addSvgIcon('ic-close', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_close.svg'))
      .addSvgIcon('ic-close-w', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_close_white.svg'))
      .addSvgIcon('ic-delete', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_deleted.svg'))
      .addSvgIcon('ic-download', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_download.svg'))
      .addSvgIcon('ic-edit', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_edit.svg'))
      .addSvgIcon('ic-error', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_error.svg'))
      .addSvgIcon('ic-forum', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_forum.svg'))
      .addSvgIcon('ic-lock', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_lock.svg'))
      .addSvgIcon('ic-mail', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic-mail.svg'))
      .addSvgIcon('ic-option', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_option.svg'))
      .addSvgIcon('ic-profile-on', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_profile_on.svg'))
      .addSvgIcon('ic-progress', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_progress.svg'))
      .addSvgIcon('ic-send', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_send.svg'))
      .addSvgIcon('ic-star-off', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_star_off.svg'))
      .addSvgIcon('ic-star-on', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_star_on.svg'))
      .addSvgIcon('ic-store', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_store.svg'))
      .addSvgIcon('ic-trash', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_trash.svg'))
      .addSvgIcon('ic-unlock', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_unlock.svg'))
      .addSvgIcon('ic-unlock-w', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_unlock_white.svg'))
      .addSvgIcon('ic-search', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic-search.svg'))
      .addSvgIcon('ic-add', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic-add.svg'))
      .addSvgIcon('ic-message', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic-message.svg'))
      .addSvgIcon('ic-grid', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic-grid.svg'))
      .addSvgIcon('ic-list', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic-list.svg'))
      ;
  }
}
