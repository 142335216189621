import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ngfModule } from 'angular-file';

/* ANGULAR MATERIAL */
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatNativeDateModule } from '@angular/material/core';

/* FIREBASE */
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from '../../environments/environment';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';


/* TRANSLATE */
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CardComponent } from './card/card.component';
import { FilePreviewOverlayComponent } from './file-preview-overlay/file-preview-overlay.component';
import { DialogComponent } from './dialog/dialog.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
import { PhotoViewerComponent } from './photo-viewer/photo-viewer.component';
import { PhotoGridComponent } from './photo-grid/photo-grid.component';
import { LoadingComponent } from './loading/loading.component'
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
    declarations: [CardComponent,
        FilePreviewOverlayComponent,
        DialogComponent,
        SnackbarComponent,
        PhotoViewerComponent,
        PhotoGridComponent,
        LoadingComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFirestoreModule,
        AngularFireModule.initializeApp(environment.firebase),
        RouterModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatTableModule,
        MatSidenavModule,
        MatListModule,
        MatMenuModule,
        MatSelectModule,
        MatCardModule,
        MatToolbarModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        MatDialogModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatGridListModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: false
        }),
        ngfModule,
    ],
    exports: [
        MatSliderModule,
        AngularFirestoreModule,
        AngularFireAuthModule,
        RouterModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatTableModule,
        MatSidenavModule,
        MatListModule,
        MatMenuModule,
        MatSelectModule,
        MatCardModule,
        MatToolbarModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        MatDialogModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatGridListModule,
        TranslateModule,
        CardComponent,
        ngfModule,
        PhotoViewerComponent,
        PhotoGridComponent,
        LoadingComponent
    ],
    providers: [
        AngularFirestore
    ]
})
export class SharedModule { }
