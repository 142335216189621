import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { map, take } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AuthService {

    public user: any;
    private userID: any;
    public userLogged: any;
    private authState: any = null;
    public currentUser$: Subject<any> = new Subject();
    constructor(
        public db: AngularFirestore,
        private afAuth: AngularFireAuth
    ) {
        this.afAuth.authState.subscribe(resp => {
            if (resp) {
                this.userID = resp.uid;
                this.getUserById(this.userID).pipe(take(1))
                    .subscribe(currentUser => {
                        this.user = currentUser;
                        this.currentUser$.next(currentUser);
                    });
            } else {
                this.currentUser$.next(null);
            }
            this.authState = resp;
        });
    }



    get authenticated(): boolean {
        return this.authState !== null;
    }

    get observerCurrentUser() {
        return this.afAuth.authState;
    }

    isAdmin(email) {
        return this.db.collection('admin', ref => ref.where("email", "==", email)).snapshotChanges()
            .pipe(
                map(array => {
                    const element = array.map((value: any) => {
                        const data = value.payload.doc.data();
                        const id = value.payload.doc.id;
                        return { id, ...data };
                    });
                    return element;
                })
            )
    }

    getUserAdmin(email) {
        let admin = this.db.collection('admin', ref => ref.where("email", "==", email)).snapshotChanges().pipe(
            take(1),
            map(userAdmin => {
                const element = userAdmin.map((value: any) => {
                    const data = value.payload.doc.data();
                    const id = value.payload.doc.id;
                    return { id, ...data };
                })
                return element;
            })
        ).toPromise();

        return admin;
    }

    getUserSpecialists(email) {
        let specialists = this.db.collection('specialist', ref => ref.where("email", "==", email)).snapshotChanges().pipe(
            take(1),
            map(userSpecialist => {
                const element = userSpecialist.map((value: any) => {
                    const data = value.payload.doc.data();
                    const id = value.payload.doc.id;
                    return { id, ...data };
                })
                return element;
            })
        ).toPromise();

        return specialists;
    }


    get getUserID() {
        return this.userID;
    }

    get currentUser(): any {
        return this.authenticated ? this.authState : null;
    }

    public async requestChangePass(credentials, newPass) {
        try {
            await this.user.reauthenticateWithCredential(credentials);
            const resp = await this.user.updatePassword(newPass);
            return true;
        } catch (error) {
            return false;
        }
    }

    public verifyEmail(oob: string) {
        return this.afAuth.applyActionCode(oob);
    }

    public async login(email, password) {
        try {
            const login = await this.afAuth.signInWithEmailAndPassword(email, password);
            this.userLogged = login.user
            return login;
        } catch (err) {
            return err;
        }
    }



    get isLogged() {
        return (this.userLogged !== undefined) ? true : false;
    }

    public async logout() {
        try {
            await this.afAuth.signOut();
            this.currentUser$.next(null);
        } catch (err) {
        }
    }

    public async resetPassword(email) {
        try {
            const resetPassword = await this.afAuth.sendPasswordResetEmail(email);
            return resetPassword;
        } catch (err) {
            return err;
        }
    }

    public async newPassword(code, newPassword) {
        try {
            const changed = await this.afAuth.confirmPasswordReset(code, newPassword);
            return changed;
        } catch (err) {
            throw err;
        }
    }

    public getUserById(id) {
        return this.db.collection('user').doc(id).get().pipe(
            map((usr: any) => {
                const data = usr.data();
                const id = usr.id;
                return { id, ...data };
            }));
    }
}
