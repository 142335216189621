import { Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {
  private subs = new SubSink();

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable<boolean>(observer => {
      this.subs.add(
        this.authService.observerCurrentUser.subscribe(async (auth) => {
          try {
            if (auth) {
              let user = await this.authService.getUserAdmin(auth.email);
              let specialists = await this.authService.getUserSpecialists(auth.email);
              user = user.concat(specialists);
              if (user.length > 0) {
                this.router.navigate(['/users']);
                observer.next(false);
              } else {
                observer.next(true);
              }
            } else {
              observer.next(true);
            }
          } catch (error) {
            throw error;

          }

        })
      )
    });
  }
}
