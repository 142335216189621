import { DownloadPhotosService } from './../../services/download-photos.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, Inject } from '@angular/core';
import { FILE_PREVIEW_DIALOG_DATA } from './../../services/file-preview-overlay.tokens';
import { FilePreviewOverlayRef } from './../../services/file-preview-overlay-ref';

@Component({
  selector: 'app-file-preview-overlay',
  templateUrl: './file-preview-overlay.component.html',
  styleUrls: ['./file-preview-overlay.component.scss']
})
export class FilePreviewOverlayComponent implements OnInit {

  public trustedUrl: any;

  constructor(
    private download: DownloadPhotosService,
    public dialogRef: FilePreviewOverlayRef,
    @Inject(FILE_PREVIEW_DIALOG_DATA) public data: any, private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.image);
  }

  public onClose() {
    this.dialogRef.close();
  }

  public downloadPhoto() {
    this.download.getFile(this.data.image).subscribe(res => {
      const blob = new Blob([res], { type: 'image/jpg' });
      const url = URL.createObjectURL(blob);
      this.downloadUrl(url, this.data.title);
      URL.revokeObjectURL(url);
    });
  }

  downloadUrl(url: string, fileName: string) {
    const a: any = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
  }

}
