import { ActionComponent } from 'src/app/action/action.component';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { NoAuthGuard } from './shared/guards/no-auth.guard';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: 'favoritos',
    loadChildren: () => import('src/app/favourites/favourites.module').then(m => m.FavouritesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('src/app/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users/:id',
    loadChildren: () => import('src/app/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'conversations',
    loadChildren: () => import('src/app/conversations/conversations.module').then(m => m.ConversationsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    loadChildren: () => import('src/app/notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'advertising',
    loadChildren: () => import('src/app/advertising/advertising.module').then(m => m.AdvertisingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('src/app/auth/auth.module').then(m => m.AuthModule),
    canActivate: [NoAuthGuard]
  },
  {
    path: 'action',
    component: ActionComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
