import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadPhotosService {

  constructor(
    private http: HttpClient
  ) { }

  getFile(photo: any) {
    return this.http.get(photo, { responseType: 'arraybuffer' });
  }
}
