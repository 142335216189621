import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import moment from 'moment';
moment.locale('es');

@Component({
  selector: 'sv-photo-grid',
  templateUrl: './photo-grid.component.html',
  styleUrls: ['./photo-grid.component.scss']
})
export class PhotoGridComponent implements OnInit {
  @Input() allSelected = true;
  @Input() id: string;
  @Input() date: string;
  @Input() photos: any;
  @Output() photosSelected = new EventEmitter();
  displayDate = '';
  selectedArray = [];

  constructor(
  ) { }

  ngOnInit() {
    this.displayDate = this.date;
    this.date = this.date.replace(', ', '_');
    this.date = this.date.replace('de ', 'de_');
    this.date = this.date.replace(' ', '_');
  }

  onSelected(photo: any): void {
    const existingPhotoIdx = this.selectedArray.findIndex(p => p.url === photo.url);
    if (existingPhotoIdx === -1) {
      this.selectedArray.push(photo);
    } else {
      this.selectedArray[existingPhotoIdx].selected = photo.selected;
    }
    this.photosSelected.emit(this.selectedArray);
  }
}
